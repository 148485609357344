import PageComponent from '../../common/component/page-component';

class Accordion extends PageComponent {
	constructor({
					root,
					element
				}) {
		super({root: root, element: element});
	}

	prepare() {
		// TODO: refactor to proper component implementation
		const accordions = this.element.querySelectorAll('.rbn-akkordeon');
		for (let i = 0; i < accordions.length; i++) {
			accordions[i].querySelector('.rbn-akkordeon__head').addEventListener('click', () => {
				accordions[i].querySelector('.rbn-akkordeon__btn').classList.toggle('rbn-akkordeon__btn_active');
				const panel  = accordions[i].querySelector('.rbn-akkordeon__body');
				panel.classList.toggle('rbn-akkordeon__body_active');
			});
		}
	}

}

export default Accordion;
