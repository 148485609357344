import Macy from 'macy-mod'; // bildsplate

if (document.querySelector('.rbn-gallery__gridImg')) {
	const macyArray = document.querySelectorAll('.rbn-gallery__gridImg');
	macyArray.forEach((grid) => {
		Macy({
			container: grid,
			columns: 2,
			margin: {
				x: 32,
				y: 40
			}
		});
	});
}
