const events = document.querySelectorAll('.rbn-events');
if(events) {
	events.forEach((event) => {
		//reverse for mobile  (akkordeon and title)
		const eventsRow = event.querySelector('.rbn-events__row');
		const eventsRowSecond = event.querySelector('.rbn-events__rowSecond');
		if(eventsRow) {
			let width = window.innerWidth;
			if (width < 992 ) {
				eventsRowSecond.removeAttribute('style');
				eventsRow.setAttribute('style', 'flex-direction: column-reverse');
			} else {
				eventsRow.removeAttribute('style');
				eventsRowSecond.setAttribute('style', 'margin-top: 32px');
			}
			window.addEventListener('resize', () => {
				width = window.innerWidth;
				if (width < 992 ) {
					eventsRow.setAttribute('style', 'flex-direction: column-reverse');
				} else {
					eventsRow.removeAttribute('style');
				}
			});
		}
		// text value
		const lang = document.documentElement.lang;
		const showDateZone = event.querySelector('.rbn-events__inputDate');
		let dateValue = null;
		if (event.querySelector('.rbn-events__dateInput') && showDateZone) {
			dateValue = event.querySelector('.rbn-events__dateInput').value;
			let converDate  = new Date(dateValue);
			const options = {year: 'numeric', month: 'long'};
			if(lang === 'de') {
				showDateZone.innerHTML = converDate.toLocaleDateString('de-DE', options);
			} else {
				showDateZone.innerHTML = converDate.toLocaleDateString('en-EN', options);
			}
			const date = event.querySelector('.rbn-events__dateInput');
			sortEventsByDate(event, dateValue);
			date.addEventListener('change', () => {
				dateValue = event.querySelector('.rbn-events__dateInput').value;
				converDate  = new Date(dateValue);
				sortEventsByDate(event, dateValue);
				//displayEvent(event);
				if(lang === 'de') {
					showDateZone.innerHTML = converDate.toLocaleDateString('de-DE', options);
				} else {
					showDateZone.innerHTML = converDate.toLocaleDateString('en-EN', options);
				}
			});
		} else {
			displayEvent(event);
		}
	});

}

function sortEventsByDate(eventBlock, dateVaue) {
		const events1 = eventBlock.querySelectorAll('.rbn-event'); //all event
		const dateArray = dateVaue.split('-'); // 2012  04  (year and month) by sort
		const tmp1 = (splitByIndex(dateArray[0], 2)).split(',');// 20 12 (year) by sort
		events1.forEach((event) => {
			const tmp = (event.querySelector('.rbn-events__date').textContent).split('.'); // dd mm yy
			if(dateArray[1] === tmp[1] && tmp1[1] === tmp[2]) {
				event.classList.add('rbn-event_active');
			} else {
				event.classList.remove('rbn-event_active');
			}
		});
}

function splitByIndex(value, index) {
	return value.substring(0, index) + ',' + value.substring(index);
}

function displayEvent(eventBlock) {
	const tmp3 = eventBlock.querySelectorAll('.rbn-event');
	tmp3.forEach((event) => {
		event.classList.add('rbn-event_active');
	});
}
